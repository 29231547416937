'use strict';

export class JobInfoDTO {
  isError: boolean;
  typeCode: string;
  materialNumber: string;
  phCode: string;
  jobId: string;
  processingUtcTime: string;
  status: string;
  titleEdzLink: string;
  hrefEdzLink: string;
  titlePdfLink: string;
  hrefPdfLink: string;
  hrefOutput: string;

  constructor(obj) {
    // assign inputs
    this.isError = false;
    this.typeCode = obj.typeCode ? obj.typeCode : '';
    this.materialNumber = obj.typeCode ? obj.typeCode : '';
    this.phCode = obj.phCode ? obj.phCode : '';
    this.jobId = obj.jobId ? obj.jobId : '';
    this.processingUtcTime = obj.processingUtcTime ? obj.processingUtcTime : '';
    this.status = obj.status ? obj.status : '';
    this.titleEdzLink = obj.titleEdzLink ? obj.titleEdzLink : '';
    this.hrefEdzLink = obj.hrefEdzLink ? obj.hrefEdzLink : '';
    this.titlePdfLink = obj.titlePdfLink ? obj.titlePdfLink : '';
    this.hrefPdfLink = obj.hrefPdfLink ? obj.hrefPdfLink : '';
    this.hrefOutput = obj.hrefOutput ? obj.hrefOutput : '';
  }
}

