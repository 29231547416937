import { NGXLogger } from 'ngx-logger';
import { JobInfoDTO } from '../_models/job-info-dto';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { DEFINITIONS } from '../definitions';

type ICallback = (response: any) => void;

const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache'
  })
};
@Injectable({
  providedIn: 'root'
})

export class JobService {

  private fnCallback;
  private actJobId: string;
  private actTypeCode: string;
  private lastResponse: object;
  private queryCancelPollingActJob = false;

  constructor(private logger: NGXLogger,
              private http: HttpClient) {
    this.logger.info('(job.service) => constructor()');
  }

  /**
   * GET /jobs/:jobId
   * Returns status information for the specific job
   * @param :jobService  JobService instance of caller
   * @param :jobId       The job id of the related job
   */
  private pollJobStatus(jobService: JobService, jobInfoDTO: JobInfoDTO) {
    let locOrigin;
    if (!location.origin) {
      locOrigin = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    } else {
      locOrigin = location.origin;
    }
    const url = locOrigin + DEFINITIONS.serverUrlStatus + jobService.actJobId;

    // poll job status from jobserver
    jobService.http.get(url, HTTP_OPTIONS).subscribe(
      // success
      (result) => {
        const newJobInfoDTO = new JobInfoDTO(result);
        if (!this.queryCancelPollingActJob && this.actJobId === jobInfoDTO.jobId) {

          if (newJobInfoDTO.status === DEFINITIONS.jobState_Queued || newJobInfoDTO.status === DEFINITIONS.jobState_Running) {
            // use arrow function to forward 'this' context
            setTimeout(() => { this.pollJobStatus(jobService, newJobInfoDTO); }, DEFINITIONS.pollingInterval);
          }
          this.lastResponse = newJobInfoDTO;
          jobService.fnCallback(newJobInfoDTO);
        }
      },
      // error
      (response) => {
        response.isError = true;
        this.logger.error('(job.service) GET-Response (poll job status): ' + response.message);
        jobService.fnCallback(response);
      });
  }

  /**
   * POST /jobs
   * Posts a new job to the job queue for specific typeCode (query param)
   * @param :typeCode   The type code for the generator
   * @param :statusCallback  Callback to process job status
   */
   public sendJob(typeCode: string, materialNumber: string, statusCallback: ICallback) {
    this.actJobId = undefined;
    this.lastResponse = undefined;
    this.fnCallback = statusCallback;
    this.actTypeCode = typeCode;
    this.queryCancelPollingActJob = false;

    let locOrigin;
    if (!location.origin) {
      locOrigin = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    } else {
      locOrigin = location.origin;
    }
    const url = locOrigin + DEFINITIONS.serverUrlStatus + '?typecode=' + encodeURIComponent(typeCode) + '&materialnumber=' + materialNumber;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      })
    };
    // poll job status from jobserver
    this.logger.info('(job.service) POST new Job: ' + url);
    this.http.post(url, options).subscribe(
      // success
      (response) => {
        const jobInfoDTO = new JobInfoDTO(response);
        this.lastResponse = jobInfoDTO;
        this.actJobId = jobInfoDTO.jobId;
        // this.pollJobStatus(this, this.actJobId);
        if (jobInfoDTO.status && !jobInfoDTO.status.startsWith('Finished')) {
          this.pollJobStatus(this, jobInfoDTO);
        }
        statusCallback(jobInfoDTO);
      },
      // error
      (response) => {
        // return $q.reject(response);
        response.isError = true;
        this.logger.error('(job.service) POST-Response (new job): ' + response.message);
        statusCallback(response);
      });
  }

  /**
   * GET /jobs/:jobId/downloads
   * Returns the available download links to the client
   * @param :jobId              The job id of the related job
   * @param :downloadCallback   Download callback
   */
  public getDownloadLink(jobId: string, downloadCallback: ICallback) {
    let locOrigin;
    if (!location.origin) {
      locOrigin = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    } else { 
      locOrigin = location.origin; 
    }
    const url = locOrigin + DEFINITIONS.serverUrlStatus + jobId + DEFINITIONS.serverUrlDownload;
    this.logger.info('(job.service) GET download links: ' + url);

    // poll job status from jobserver
    this.http.get(url, HTTP_OPTIONS).subscribe(
      // success
      (response) => {
        downloadCallback(response);
      },
      // error
      (response) => {
        response.isError = true;
        this.logger.error('(job.service) GET-Response (download links): ' + response.message);
        downloadCallback(response);
      });
  }

  /**
   * GET /jobs/:jobId/downloads
   * Manually download of a single file. The file is requested as 'blob' from the server.
   * The server provided the file as a stream.
   * @param :url    Url of the file to download
   */
  public downloadSingleFile(url: string, orderId?: string, typeCode?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      // provide order info for logging in ecad.generator.server
      if (orderId && typeCode) {
        url = url + '?orderId=' + orderId + '&typeCode=' + typeCode;
      }
      this.http.get(url, {
        responseType: 'blob'
      }).subscribe(
        // success
        (response) => {
          resolve(response);
        },
        // error
        (response) => {
          response.isError = true;
          reject(response);
        });
    });
  }

  /**
   * Try to attach client view to current/last job
   * Required to reentrant generator page via sidebar menu
   * @param :typeCode        Type code which should be attached
   * @param :statusCallback  Callback to process job status
   */
  public attachClientToJob(typeCode: string, statusCallback: ICallback) {
    if (typeCode !== this.actTypeCode) {
      // different typeCode: stop polling current job
      this.queryCancelPollingActJob = true;
    } else {
      // equal typeCode: try to attach to last job
      this.fnCallback = statusCallback;

      // send last response to client view to init client view
      if (this.lastResponse) {
        // check if current job is already finished
        if (this.lastResponse['status'] && this.lastResponse['status'].startsWith('Finished')) {
          const jobInfoDTO = new JobInfoDTO(this.lastResponse);
          statusCallback(jobInfoDTO);
          return;
        }
        this.queryCancelPollingActJob = false;
        this.actJobId = this.lastResponse['jobId'];
        this.pollJobStatus(this, new JobInfoDTO({ jobId: this.actJobId}) );
      }
    }
  }

  /**
   * Stop polling
   */
  public detachClient() {
    this.queryCancelPollingActJob = true;
  }

}
